.resume-section-light {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
  background-color: #FFFFFF;
}

.resume-section-dark {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
  background-color: #2C3639;
}

.resume-section-title {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.resume-section:hover {
  transition: 0.1s;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2);
}

.resume-details-title {
  font-size: 20px;
  text-align: left;
  display: flex;
}

.resume-details-title-one {
  font-weight: 500;
}

.resume-details-title-two {
  font-style: italic;
  opacity: 0.85;
}

.resume-details-subtitle {
  text-align: left;
  font-size: 18px;
  opacity: 0.85;
}

.resume-details-description {
  text-align: left;
}

.tooltip-arrow {
    display: none !important;
}

.resume-link {
  display: inline-block;
}

.resume-download-link,
resume-download-link:hover,
resume-download-link:focus,
resume-download-link:active,
resume-download-link:visited,
resume-download-link:link {
  color: inherit;
  text-decoration: none;
}

.leetcode-title {
  font-size: 18px;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

.leetcode-error-text {
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.leetcode-rate-text {
  font-size: 10px;
}

.leetcode-loading {
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;
}

.leetcode-link {
  cursor: pointer;
}
