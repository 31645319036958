html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.full-height {
  height: 100%;
}

.shadow-image {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}

.shadow-image:hover{
  transition: 0.1s;
  box-shadow: 2px 10px 14px 2px rgba(0, 0, 0, 0.2);
}

.error-image {
  border-radius: 0.5rem;
  width: 80%;
  height: 70%;
  max-height: 450px;
  max-width: 450px;
}

.about-image {
  height: 175px;
  width: 175px;
  border-radius: 0.5rem;
  pointer-events: none;
}

.section-parent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 0.25rem 0px 0.25rem 0px;
  text-align: center;
}

.section-child {
  /* display: inline-block; */
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
  text-align: center;
  margin: 0.3rem 0.5rem;
  padding: 0.3rem 0.5rem;
  vertical-align: middle;
  font-size: 1.15em;
}

.section-text {
  flex-basis: 66%;
}

.light {
  color: black;
  background-color: #fafcfc;
}

.dark {
  color: #FAF8F1;
  background-color: #435055;
}

.hover-color:hover {
  color: #89C6C6;
}

.hover-color-light:hover {
  filter: invert(78%) sepia(32%) saturate(310%) hue-rotate(131deg) brightness(90%) contrast(90%);
}

.hover-color-dark:hover {
  filter: brightness(0) saturate(100%) invert(78%) sepia(32%) saturate(310%) hue-rotate(131deg) brightness(90%) contrast(90%);
}

.hover-color-dark, .hover-color-light {
  transition-duration: 0.05s;
}

.toggle-container.dark {
  background-image: linear-gradient(109.8deg,
                    rgba(103,181,181,1) -10%,
                    rgba(41,14,151,1) 100%,
                    rgba(62,5,116,1) 100%
                    );
}

.toggle-container {
  height: 35px;
  width: 80px;
  background-image: radial-gradient(circle farthest-corner at 10% 20%,
                    #FF8C00 -10%, #ffc0cb 90%);
  /* background-image: radial-gradient(circle farthest-corner at 10% 20%,
                    rgba(253,203,50,1) 0%,
                    rgba(244,56,98,1) 100.2%); */
  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
  transition: all .3s;
}

.toggle-icon-container {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  overflow: hidden;
  color: #000000;
}
